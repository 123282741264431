import { useEffect } from "react";

function PlanificacionVista({ plan }) {
    let capacidades = [];
    let contenidos = [];
    
    try {
        // Parsear las cadenas en arreglos
        capacidades = JSON.parse(plan.capacidades || "[]"); // Asegura que no sea null/undefined
        contenidos = JSON.parse(plan.contenidos_generales|| "[]");
    } catch (error) {
        console.error("Error al parsear capacidades o contenidos:", error);
    }
    return (    
      <div className="mx-3">
        
        <h3>{plan.nombre_espacio} {plan.orden} {plan.denominacion}</h3>
        <h6>cohorte {plan.cohorte}</h6>
        <hr className="my-4"/>
        <h5>Introduccion</h5>
        <p className="mx-3">{plan.introduccion}</p>
        <h5>Propositos</h5>
        <p className="mx-3">{plan.propositos}</p>
        <h5>Capacidades</h5>
        {Array.isArray(capacidades) && capacidades.length > 0 ? (
          <ul>
            {capacidades.map((capacidad, index) => (
              <li key={index}>{capacidad}</li>
            ))}
          </ul>
        ) : (
          <p>No hay capacidades disponibles</p>
        )}
        <h5>Contenidos</h5>
        {Array.isArray(contenidos) && contenidos.length > 0 ? (
          <ul>
            {contenidos.map((contenido, index) => (
              <li key={index}>{contenido}</li>
            ))}
          </ul>
        ) : (
          <p>No hay contenidos disponibles</p>
        )}
        <h5>Estrategia metodologica</h5>
        <p className="mx-3">{plan.estrategia_metodologica}</p>
        <h5>Evaluacion</h5>
        <p className="mx-3">{plan.evaluacion}</p>
        <h5>Entorno</h5>
        <p className="mx-3">{plan.entorno}</p>
        <h5>Recursos</h5>
        <p className="mx-3">{plan.recursos}</p>
        <h5>Bibliografia</h5>
        
          <ul className="square">
            {plan.bibliografia.split('-').map((bib, index) => (
              bib && <li key={index}>{bib}</li>
            ))}
          </ul>
        
      </div>
    );
  }
  
  export default PlanificacionVista;
  