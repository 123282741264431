const CONFIG = {
  //  BASE_URL: 'http://10.0.14.54:3000/',     //IP Polo
  //  API_URL: 'http://10.0.14.54/tresatec/API', //IP Polo
//    BASE_URL: 'http://192.168.1.16:3000/',  //IP ofi 32
//    API_URL: 'http://192.168.1.16/tresatec/API', //IP ofi 32
    //BASE_URL: 'http://localhost:3000/',  //Localhost
    //API_URL: 'http://localhost/tresatec/API', //Localhost
    //BASE_URL: 'http://www.institutopetitdemeurville.com.ar/',  //Localhost
    //API_URL: 'http://www.institutopetitdemeurville.com.ar/API'

  //CON https
    BASE_URL: 'https://www.institutopetitdemeurville.com.ar/',  //Localhost
    API_URL: 'https://www.institutopetitdemeurville.com.ar/API'

  //  BASE_URL: 'http://192.168.1.49:3000/',  //IP casa
  //  API_URL: 'http://192.168.1.49/tresatec/API', //IP casa
    
  };
  
export default CONFIG;

