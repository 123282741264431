import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PrincipalAdmin from "./principal/PrincipalAdmin";
import PrincipalDocentes from "./principal/PrincipalDocentes";
import PrincipalEstudiante from "./principal/PrincipalEstudiante";
import PrincipalTesorero from "./principal/PrincipalTesorero";

function Principal({acceder,rol, mensajesSinLeer, configuracion}){
    const navigate = useNavigate();
    //const loggeduserROlId =localStorage.getItem('loggeduserRolId');

    useEffect(()=>{   
        if(acceder){
            console.log(rol);
            if(rol===null){
                navigate("/");
            }
        }else{
            localStorage.clear();
            navigate("/");
        }
      },[])


    return(
        <>
            <div className='container-principal'>
                
                {rol==1 && <h5>Area Super admin</h5>}
                {(rol==2 || rol==3) && <PrincipalAdmin mensajesSinLeer={mensajesSinLeer} rol={rol} />}
                {(rol==6 || rol==5) && <PrincipalDocentes mensajesSinLeer={mensajesSinLeer} rol={rol}/>}
                {rol==7  && <PrincipalEstudiante rol={rol} configuracion={configuracion}/>}
                {rol==13  && <PrincipalTesorero rol={rol} mensajesSinLeer={mensajesSinLeer}/>}   
                {(rol ==8) && 
                    <>
                    <h5>Area estudiante/TUTOR</h5>
                    <button type="button" className="btn btn-primary">Inscripcion</button>
                    </>                
                }
                
            </div>
        </>
    );
}

export default Principal;