import './css/Aulas.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { show_alerta } from '../../funciones.js';
import PerfilLogo from '../usuarios/PerfilLogo.js';
import CONFIG from '../../config';

const URL = `${CONFIG.API_URL}/operarCursos.php`;
const URL_LISTAR = `${CONFIG.API_URL}/listarUsuarios.php`;

function CursoEquipoDocente({ id_curso_grupo, docentesCurso }) {
    const [docentes, setDocentes] = useState([]);
    const [docenteSeleccionado, setDocenteSeleccionado] = useState('');
    const [buscar, setBuscar] = useState('');
    const [modalAbierto, setModalAbierto] = useState(false);

    useEffect(() => {
        axios.post(URL_LISTAR, { 'id_rol': 6, 'modo': 'buscaUsuariosPorRol' })
            .then(res => {
                if (!res.data.error) {
                    setDocentes(res.data);
                } else {
                    setDocentes([]);
                }
            })
            .catch(err => {
                console.log(err);
                show_alerta('Error en la solicitud', 'error');
            });
    }, [id_curso_grupo]);

    const handleSubmint = async (e) => {
        e.preventDefault();
        try {
            const docenteYaAsignado = docentesCurso.some(docente => docente.id === docenteSeleccionado);

            if (docenteYaAsignado) {
                show_alerta('Docente ya está asignado a este curso', 'error');
                return;
            }

            const response = await axios.post(URL, {
                'id_curso_grupo': id_curso_grupo,
                'modo': 'asignarDocente',
                'idDocente': docenteSeleccionado
            });
            const [tipo, msj] = response.data;
            show_alerta(msj, tipo);
            setDocentes([]);
        } catch (error) {
            show_alerta('Error en la solicitud', 'error');
            console.error(error);
        }
    };

    const handleBuscarChange = (e) => {
        setBuscar(e.target.value);
    };

    const filtroDocente = buscar
        ? docentes.filter((dato) =>
            dato.nombre.toLowerCase().includes(buscar.toLowerCase()) ||
            dato.apellido.toLowerCase().includes(buscar.toLowerCase()) ||
            dato.documento.toString().toLowerCase().includes(buscar.toLowerCase())
        )
        : docentes;

    return (
        <>
            <div>
                <div className='row border-bottom mx-2 mb-2'>
                    <div className='col-8'>
                        <h5><i className="fa-solid fa-chalkboard-user"></i> Total de docentes el curso {Array.isArray(docentesCurso) ? docentesCurso.length : 0}</h5>
                    </div>
                    <div className='col-4 text-end'>
                        <button type='button' className='btn btn-light btn-sm' data-bs-toggle="modal" data-bs-target={`#modalAgregarDocente_${id_curso_grupo}`} onClick={() => setModalAbierto(true)}>+ Docente</button>
                    </div>
                </div>

                <div className='mx-2'>
                    {Array.isArray(docentesCurso) && docentesCurso.length > 0 &&
                        docentesCurso.map((dc) => (
                            <div className='row' key={dc.id}>
                                <div className='col-8 my-1'><PerfilLogo usuario={dc} version="extendida" /></div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="modal fade" id={`modalAgregarDocente_${id_curso_grupo}`} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: modalAbierto ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                Docentes/Auxiliares
                                <div className="input-group ">
                                    <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-magnifying-glass"></i></span>
                                    <input type="text" className="form-control" id='buscar' name='buscar' value={buscar} onChange={handleBuscarChange} placeholder='buscar...' aria-label='buscar' aria-describedby='basic-addon1' />
                                </div>
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmint}>
                                <div className='contenidoScroleable'>
                                    <input type='hidden' name="id_curso_grupo" value={id_curso_grupo} readOnly />
                                    {filtroDocente.map((d) => (
                                        <div key={d.id}>
                                            <input className="form-check-input" type="radio" name='docente' onClick={() => setDocenteSeleccionado(d.id)} id={`chek_${d.id}`} value={d.id} />
                                            <label className="form-check-label mx-1 small" htmlFor={`chek_${d.id}`}> {d.apellido}, {d.nombre} -{d.documento} ({d.nombre_rol})</label>
                                        </div>
                                    ))}
                                </div>
                                <hr />
                                <button type="button" id={`boton-cerrar_${id_curso_grupo}`} name="boton-cerrar" className="btn btn-secondary m-1" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className='btn btn-success'>Aceptar seleccionado</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CursoEquipoDocente;
