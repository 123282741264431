import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { show_alerta } from '../../funciones.js';

import CONFIG from '../../config';
import PerfilInfo from "./PerfilInfo";
import FichaUsuarioRolInfo from "./FichaUsuarioRolInfo";
import PerfilForm from "./PerfilForm";

const URL_LISTAR = `${CONFIG.API_URL}/listarUsuarios.php`;
const URL  = `${CONFIG.API_URL}/operarTablaUsuario.php`;

function FichaUsuario({configuracion}) {
    const { usuarioId } = useParams();
    const defaultFilePerfil = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
    const [perfil, setPerfil] = useState({
        id: null,
        nombre: "",
        apellido: "",
        apodo: "",
        imagen_perfil: "",
        fecnac: "",
        email: "",
        genero: "",
        telefono: "",
        calle: "",
        numero: "",
        piso: "",
        depto: "",
        ciudad: "",
        provincia: ""
    });
    const [roles, setRoles] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0); // Estado para el índice activo
    const [mostrarEditar, setMostrarEditar] = useState(false);

    useEffect(() => {
        obtenerDatos();
    }, [usuarioId]);

    const obtenerDatos = async () => {
        const data = {
            'id_usuario': usuarioId,
            'modo': 'buscarFichalUsuario'
        };
        
        try {
            const res = await axios.post(URL_LISTAR, data);
            if (!res.data.error) {
                setPerfil(res.data.info);
                setRoles(res.data.roles);
            } else {
                setPerfil(null);
                setRoles([]);
            }
        } catch (err) {
            console.error(err);
        } 
    };
    const actualizarData = (data) =>{
        //console.log("dataaaaa: "+data);
        enviarSolicitud("POST", data);
    };

    const enviarSolicitud = async (metodo, parametros) =>{
        await axios({method:metodo, url:URL, data:parametros})
            .then(res =>{
                var tipo = res.data[0];
                var msj = res.data[1];
                //console.log(res);
                //var sql = res.data[2];
                //console.log(tipo + "-" + msj+'-'+sql);
                setMostrarEditar(false);
                setPerfil(perfil);
                obtenerDatos();
                show_alerta(msj,tipo);
            })
            .catch(err=>{
                show_alerta('Error en la solicitud ','error');
                console.log(err);
            })
    }

    const previewFoto=(e)=>{
        //console.log(e.target.files[0]);
        let img= document.getElementById('imagen');
        if( e.target.files[0] ){
            const reader = new FileReader( );
            reader.onload = function( e ){
                img.src = e.target.result;
            }
            reader.readAsDataURL(e.target.files[0])
        }else{
            img.src = defaultFilePerfil;
        }
    }

    const obtenerEdad = (dateString) => {
        let hoy = new Date();
        let fechaNacimiento = new Date(dateString);
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
        let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
        if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
            edad--;
        }
        return edad;
    };


    return (
        <div className='container-principal'>
            <div className="row">
                <div className="col-6 col-sm-4"> 
                    <div className='card-superior1 d-flex justify-content-center'> 
                        <img id="imagen" className='imagen-circular' src={((perfil.imagen_perfil == null)||(perfil.imagen_perfil == '')) ? `${defaultFilePerfil}` : `${CONFIG.API_URL}/${perfil.imagen_perfil}`} alt="Imagen de perfil"/> 
                        <img src={`../img/${configuracion.logo_solo}`} alt="" className="logo_perfil" />
                    </div>
                    
                </div>
                <div className="col-6 col-sm-8 mt-4">
                    <h4 className='card-nombre text-black'>{perfil.nombre} {perfil.apellido} </h4>
                    <div className='card-apodo'>{perfil.apodo} ({obtenerEdad(perfil.fecnac)})</div>
                    <p>{perfil.email}</p>
                    
                </div>
            </div>
                
            <div className="row">
                <div className="sol-12 col-sm-4">
                    <div className='card-info-fija'>
                        <h5>Datos personales <button className={`btn ${mostrarEditar?'btn-sm btn-secondary':''}`}  onClick={() => setMostrarEditar(!mostrarEditar)}>{mostrarEditar ?'':<i className="fa-solid fa-user-pen"></i>} {mostrarEditar ? `cancelar edición` : ``}</button></h5>
                        {!mostrarEditar &&
                            <PerfilInfo perfil={perfil} />
                        }
                        {mostrarEditar && 
                            <PerfilForm perfil={perfil} actualizarData={actualizarData} previewFoto={previewFoto}/>
                        }                
                    </div>
                </div>
                <div className="col-12 col-sm-8 mt-2">
                    <h5>Roles del usaurio</h5>
                    {roles.length > 0 ? (
                        <ul className="nav nav-tabs">
                            {roles.map((rol, index) => (
                                <li key={index} className="nav-item">
                                    <a
                                        className={`nav-link ${(rol.id === activeIndex)|| (activeIndex==0) ? 'active' : ''}`}
                                        onClick={() => setActiveIndex(rol.id)}
                                        href="#"
                                    >
                                       <i className={rol.icono}></i> {rol.nombre} {rol.id}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="mt-4">Sin roles</div>
                    )}
                    <div className="contenedor-tab ms-2">
                        {activeIndex == 1 && <h5 className="mt-3">Administrador Principal</h5>}
                        {activeIndex == 2 && <h5 className="mt-3">Administrador de datos</h5>}
                        {activeIndex == 3 && <h5 className="mt-3">Equipo directivo de la institución</h5>}
                        {activeIndex == 4 && <h5 className="mt-3">Secretario/a de la institución</h5>}
                        {((activeIndex > 4) ||(activeIndex==0)) &&<FichaUsuarioRolInfo rol={activeIndex} usuarioId={usuarioId} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FichaUsuario;
