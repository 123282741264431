import './css/Aulas.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EspacioCrudForm from './EspacioCrudForm';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { show_alerta } from '../../funciones.js';
import CONFIG from '../../config';

const URL = `${CONFIG.API_URL}/operarFormacion.php`;

function Espacios({ id_formacion }) {
  const [espacios, setEspacios] = useState([]);
  const [datoEditar, setDatoEditar] = useState(null);
  const [espaciosRaiz, setEspaciosRaiz] = useState([]);
  const [subEspaciosMap, setSubEspaciosMap] = useState({});

  const data = {
    'id': id_formacion,
    'modo': 'buscarEspaciosID'
  };

  // Función para obtener los datos
  const obtenerDatos = async () => {
    try {
      const res = await axios.post(URL, data);
      if (!res.data.error) {
        setEspacios(res.data);
        const rootSpaces = res.data.filter((dato) => !dato.correspondencia || dato.correspondencia == 0 || dato.correspondencia == -1);
        setEspaciosRaiz(rootSpaces);

        const subSpacesMap = res.data.reduce((acc, dato) => {
          if (dato.correspondencia && dato.correspondencia > 0) {
            if (!acc[dato.correspondencia]) {
              acc[dato.correspondencia] = [];
            }
            acc[dato.correspondencia].push(dato);
          }
          return acc;
        }, {});
        setSubEspaciosMap(subSpacesMap);
      } else {
        setEspacios([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    obtenerDatos();
  }, [id_formacion]);

  const enviarFormData = (data) => {
    enviarSolicitud("POST", data);
  };


    const eliminarEspacio = (id) =>{
        const MySwal= withReactContent(Swal); 
        MySwal.fire({
            title: '¿Seguro de eliminar el espacio?',
            icon: 'question', 
            text: 'No se podrá dar marcha atrás',
            showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
        })
        .then(res=>{
            if(res.isConfirmed){
                enviarSolicitud('DELETE',{'id':id, 'tabla':'espacios'})
            }else{
                show_alerta(' NO fue eliminado');
            }
        });
    };

  const enviarSolicitud = async (metodo, parametros) => {
    try {
      const res = await axios({ method: metodo, url: URL, data: parametros });
      const tipo = res.data[0];
      const msj = res.data[1];
      //console.log(res.data);
      show_alerta(msj, tipo);
      if (tipo === 'success') {
        document.getElementById('btnCerrar').click();
        obtenerDatos(); // Llamar a fetchData después de una operación exitosa
      }
    } catch (err) {
      // show_alerta('Error en la solicitud', 'error');
      console.log(err);
    }
  };
  const mostrarNombre = (orden)=>{
    let nombre="";
    switch(orden){
      case "S2":
        nombre='Sala de 2';
      break
      case "S3":
        nombre='Sala de 3';
      break
      case "S4":
        nombre='Sala de 4';
      break
      case "S5":
        nombre='Sala de 5';
      break
      case "In":
        nombre='Espacio Institucional';
      break
      default:
        nombre=orden+'°';
    }
    return nombre
  }
  return (
    <>
      <div>
        <h5>Espacios {espaciosRaiz.length}</h5>  
        <button type='button' className='btn btn-sm btn-outline-success m-2' onClick={() => setDatoEditar(null)} data-bs-toggle="modal" data-bs-target="#modalNuevoEspacio"><i className='fa-solid fa-plus'></i> espacio</button>
      </div>			
      {espaciosRaiz.length !== 0 ? 
				<table className="table table-sm ">
  				<thead>
						<tr>
							<th className="col-1">#</th>
							<th className="col-9">Espacio</th>
							<th className="col-2">acc.</th>
						</tr>
  				</thead>
  				<tbody>
					{espaciosRaiz.map(esp => (
          <tr key={esp.id} >
           	<td className='small'>{mostrarNombre(esp.orden)}</td>
           	<td className='small'>
             	{esp.correspondencia == -1 ?
               	<div>
                 	<strong>{esp.nombre_espacio} ({esp.id})</strong> - {esp.horas===0 ? '' : `${esp.horas} hs.`} {esp.dictado}
									<br />
									{subEspaciosMap[esp.id] && subEspaciosMap[esp.id].map(subEsp => (
										<div className='row' key={subEsp.id} >
											<div className='col-10 small'>{subEsp.nombre_espacio} ({subEsp.id})</div>
											<div className='col-2 small'>
												<button className='btn btn-sm btn-light m-1' onClick={() => setDatoEditar(subEsp)} data-bs-toggle="modal" data-bs-target="#modalNuevoEspacio"><i className='fa-solid fa-edit'></i></button>
												<button type='button' className='btn btn-sm btn-light m-1' onClick={() =>eliminarEspacio(subEsp.id)}><i className='fa-solid fa-trash'></i></button>
											</div>
										</div>
									))}
               	</div>
               	: <span>{esp.nombre_espacio} ({esp.id}) {esp.horas===0 ? '' : `${esp.horas} hs.`} - {esp.dictado}</span>} 
           	</td>
            <td className='small'>
             	<button className='btn btn-sm btn-warning m-1' onClick={() => setDatoEditar(esp)} data-bs-toggle="modal" data-bs-target="#modalNuevoEspacio"><i className='fa-solid fa-edit'></i></button>
             	<button type='button' className='btn btn-sm btn-danger m-1' onClick={() =>eliminarEspacio(esp.id)}><i className='fa-solid fa-trash'></i></button>        
            </td>
          </tr>
      	))}
				</tbody>
				</table>
	
			: <><hr/><span className='formacion-texto-sin_resultados'>Sin Espacios</span><hr/></>}

      <div id="modalNuevoEspacio" className="modal fade" aria-labelledby="modalNuevoEspacio" aria-hidden="true">
        <div className='modal-dialog modal-lg'>
          <div className="modal-content">
            <div className='modal-header'>
              <h1 className="modal-title fs-5" id="ModalLabel">Espacios</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className='modal-body'>
              <EspacioCrudForm enviarFormData={enviarFormData} espacios={espacios} id_formacion={id_formacion} datoEditar={datoEditar} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Espacios;
