import './css/HeaderRoles.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate } from "react-router-dom";
import PerfilLogo from './usuarios/PerfilLogo';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { show_alerta } from '../funciones.js';

import CONFIG from '../config';

const URL_LISTAR_MENSAJES = `${CONFIG.API_URL}/listarMensajes.php`;

function HeaderRoles({ acceder, rolSelect, configuracion, mensajesSinLeer, setMensajesSinLeer }) {
    const navigate = useNavigate();
    const loggeduserNombre = localStorage.getItem('loggeduserNombre');
    const datosUser = JSON.parse(localStorage.getItem('loggeddatosuser'));
    const userId =datosUser.id

    const handleLogout = () => {
        acceder(false);
        rolSelect(null);
        localStorage.clear();
        navigate("/");
    }

    const handleRol = () => {
        rolSelect(null);
        localStorage.removeItem("loggeduserRolId");
        navigate("/");
    }
    
    useEffect(() => {
        buscaMensajes(userId,'SIN_LEER');
        //nuevo para chequear mensajes nuevos
      //  const interval = setInterval(() => {
      //      buscaMensajes(userId,'SIN_LEER');
      //  }, 15000); // Consulta cada 15 segundos
        
    }, [acceder, rolSelect, configuracion, mensajesSinLeer, setMensajesSinLeer]);

    const buscaMensajes = async (id, tipo) => {
        const data = {
            'id': id,
            'tipo': tipo
        };
        try {
            const res = await axios.post(URL_LISTAR_MENSAJES, data);
            if (!res.data.error) {
                const nuevaCantidad = res.data.cantidad;
                if(nuevaCantidad !== mensajesSinLeer){
                   
                    setMensajesSinLeer(nuevaCantidad);
                    console.log('Response cantidad:', nuevaCantidad, ' mensajes:', mensajesSinLeer);
                }
            } else {
                // Manejar el error
            }
        } catch (err) {
           // show_alerta('Error en la solicitud', 'error');
            console.log(err);
        }
    };


    const title = <PerfilLogo  usuario={datosUser} version="logo_solo" />

    return (
        <header id="header" className="fixed-top" style={{
            backgroundColor: configuracion.fondo_barra_superior,
            color: configuracion.color_texto_barra_superior
        }}>
            <Navbar>
                <Container>
                    <Navbar.Brand href="#home">
                        <span className="logo1">
                            <img src={`../img/${configuracion.logo_chico}`} alt="" className="hidden-xs" />
                        </span>
                        <span className="logo-texto ms-5" style={{
                            color: configuracion.color_primario }}>{configuracion.nombre}</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className="align-derecha">
                            <Nav>
                                
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-messages">Mensajería interna</Tooltip>}>
                                    <Nav.Link onClick={() => navigate('/Mensajes')} className='mx-3'>
                                        <i className="wrap-icon fa-solid fa-envelope mr-3"></i>{mensajesSinLeer!=0 && <span className="info-mensajes-head text-white">{ mensajesSinLeer}</span>}
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-messages">{loggeduserNombre}</Tooltip>}>
                                
                                    <NavDropdown drop='start' title={title} id="basic-nav-dropdown" >
                                        <NavDropdown.Header className='nav-dropdown-item'>{loggeduserNombre}</NavDropdown.Header>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item className='nav-dropdown-item' onClick={() => navigate("/perfil")}><i className="fa-solid fa-user-pen"></i> Editar perfil</NavDropdown.Item>
                                        <NavDropdown.Item className='nav-dropdown-item' onClick={() => navigate("/Pass")}><i className="fa-solid fa-key"></i> Cambiar contraseña</NavDropdown.Item>
                                        <NavDropdown.Item className='nav-dropdown-item' onClick={handleRol}><i className="fa-solid fa-rotate"></i> Seleccionar rol</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item className='nav-dropdown-item' onClick={handleLogout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Cerrar sesión</NavDropdown.Item>
                                    </NavDropdown>
                                </OverlayTrigger>
                                
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default HeaderRoles;
