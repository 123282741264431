import './css/Aulas.css'; 
import Espera from '../Espera';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ClaseActividadesForm from './ClaseActividadesForm';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarClases.php`;

function ClaseActividades({setCantActividades, enviarSolicitud, id_curso, id_clase, setEditaActividad, editaActividad}) {
    const [actividadesCurso, setActividadesCurso] = useState([]);
    const [actividadesClase, setActividadesClase] = useState([]);
    const [trabajosCurso, setTrabajosCurso] = useState([]);
    const [cuestionarioCurso, setCuestionarioCurso] = useState([]);
    const [trabajosClase, setTrabajosClase] = useState([]);
    const [cuestionarioClase, setCuestionarioClase] = useState([]);
    
    const [visible, setVisible] = useState(false);    
    
    const data= {
        'id_curso' : id_curso,
        'modo': 'buscarActividadesEspacioCurso'
    }
    
    useEffect(() => {
        buscaActividadesCurso(data);
    }, [id_clase, editaActividad]);

    useEffect(() => {
        setCantActividades(actividadesClase.length);
    }, [trabajosClase,cuestionarioClase]);
    
    const buscaActividadesCurso = (d) =>{
        setVisible(true);
        axios.post(URL, d)
        .then(res =>{
            //console.log(res.data)
            if(!res.data.error){   
               // setActividadesCurso(res.data.trabajos)
                setTrabajosCurso(res.data.trabajos)
                //console.log(res.data.trabajos)
                
                //filtrar las trabajos de la clase actual
                setTrabajosClase(res.data.trabajos.filter((trabajo)=>{
                    if( trabajo.id_clase==id_clase ){
                        return true;
                    }
                    return false;
                }))
               
                setCuestionarioCurso(res.data.cuestionarios)
                console.log(res.data.cuestionarios)

                //filtrar las cuestionarios de la clase actual
                setCuestionarioClase(res.data.cuestionarios.filter((cuestionario)=>{
                    if( cuestionario.id_clase==id_clase ){
                        return true;
                    }
                    return false;
                }))
                setCantActividades(trabajosClase.length + cuestionarioClase.length);
            }else{
                setActividadesCurso([]);
            }
            setVisible(false);           
        })
        .catch(err=>{
            console.log(err);
        })   
    }

    const quitarActividad = (id_trabajo_clase) => {
        enviarSolicitud('DELETE', {'id': id_trabajo_clase, 'tabla': 'trabajo_clase'});
        setEditaActividad(false);
    }

    return (    
        <div>
        {editaActividad && 
            <ClaseActividadesForm 
            setEditaActividad={setEditaActividad} 
            id_clase={id_clase} 
            trabajosCurso={trabajosCurso} 
            cuestionarioCurso={cuestionarioCurso}
            enviarSolicitud={enviarSolicitud} />
        }
        {!visible ? 
            actividadesClase.length !== 0 ? actividadesClase.map((acl) => (
                <div key={acl.id} className="actividad-clase-link row">
                    <div className='col-1'>
                        <i className="icono fa-solid fa-briefcase"></i>
                    </div>  
                    <div className='col-10'>
                        <h5>{acl.titulo.substr(0, 35)} ({acl.id_trabajo_clase})</h5>
                        <p>{acl.desarrollo}</p>
                    </div>  
                    {editaActividad && <div className='col-1 d-flex justify-content-end'>
                        <a onClick={() => quitarActividad(acl.id_trabajo_clase)}>
                            <i className="fa-regular fa-trash-can"></i>
                        </a>
                    </div>}
                </div>
            )) : <div>Sin actividades</div>
        : <div className='container m-3'><Espera visible={visible} /></div>
        }                
    </div>
    );
}

export default ClaseActividades;
