import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../config';

const URL_LISTAR = `${CONFIG.API_URL}/operarCalificaciones.php`;

function Calificaciones({ acceder }) {
    const userId = localStorage.getItem('loggeduserId');
    const navigate = useNavigate();
    const [cursoParticipa, setCursoParticipa] = useState([]);
    const [valoraciones, setValoraciones] = useState([]);

    useEffect(() => {
        if (!acceder) {
            localStorage.clear();
            navigate('/');
            return null;
        }
        obtenerCursos();
        obtenerDatos();
    }, [acceder, navigate]);

    const data = {
        'id_usuario': userId,
        'modo': 'buscarCalificaciones'
    };

    const obtenerDatos = () => {
        axios.post(URL_LISTAR, data)
            .then(res => {
                if (!res.data.error) {
                    setValoraciones(res.data);
                } else {
                    setValoraciones([]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const obtenerCursos = () => {
        axios.post(URL_LISTAR, { 'id_usuario': userId, 'modo': 'buscarCursosQueParticipa' })
            .then(res => {
                if (!res.data.error) {
                    setCursoParticipa(res.data);
                } else {
                    setCursoParticipa([]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    // Agrupar por cohorte
    const cohortes = {};
    valoraciones.forEach(({ id_cohorte, cohorte, formacion, nivel, curso_id, curso_nombre, instancia_nombre, valoracion_valor }) => {
        if (!cohortes[id_cohorte]) {
            cohortes[id_cohorte] = { nombre: cohorte, formacion:formacion, nivel:nivel, cursos: {} };
        }
        if (!cohortes[id_cohorte].cursos[curso_id]) {
            cohortes[id_cohorte].cursos[curso_id] = { nombre: curso_nombre, instancias: {} };
        }
        cohortes[id_cohorte].cursos[curso_id].instancias[instancia_nombre] = valoracion_valor;
    });

    return (
        <div className='container-principal'>
            <h4>Calificaciones por cursos</h4>
            <hr></hr>
            {Object.keys(cohortes).map(cohorteId => {
                const cohorte = cohortes[cohorteId];
                // Obtener todas las instancias únicas de este cohorte
                const todasLasInstancias = Array.from(
                    new Set(
                        Object.values(cohorte.cursos).flatMap(curso => Object.keys(curso.instancias))
                    )
                );

                return (
                    <div key={cohorteId} className='cohorte-section'>
                        <h5>{cohorte.formacion} nivel {cohorte.nivel} (cohorte {cohorte.nombre})</h5>
                        <table class="table table-success table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th>Curso</th>
                                    {todasLasInstancias.map((instancia, index) => (
                                        <th key={index}>{instancia}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(cohorte.cursos).map(cursoId => {
                                    const curso = cohorte.cursos[cursoId];
                                    return (
                                        <tr key={cursoId}>
                                            <td>{curso.nombre}</td>
                                            {todasLasInstancias.map((instancia, index) => (
                                                <td key={index}>
                                                    {curso.instancias[instancia] ?? '-' /* Mostrar "-" si no hay valor */}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                );
            })}
        </div>
    );
}

export default Calificaciones;
