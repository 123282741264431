import { useEffect, useState } from "react";
import '../css/InscripcionExterna.css';
import InscripcionExternaForm from "./InscripcionExternaForm";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from 'axios';

import CONFIG from '../../config';


function InscripcionExterna({ logeado, esInscripcion, setEsInscripcion }) {
    const { codigo } = useParams();
    const [verFormRegistro, setVerFormRegistro] = useState(false);
    const [curso, setCurso] = useState('');
    const navigate = useNavigate();
    const loggeduserNombre = localStorage.getItem('loggeduserNombre');
    const datosUser = JSON.parse(localStorage.getItem('loggeddatosuser'));

    useEffect(() => {
        setEsInscripcion(true);
        BuscaCursoConCodigo(codigo)
        console.log(logeado);
    }, []);
    const BuscaCursoConCodigo = async (cod) => {
      try {
        const response = await axios.get(`${CONFIG.API_URL}/operarCursos.php?codigo=${cod}`);
       // console.log("dd:"+JSON.stringify(response.data.curso));
        if (response.data.resultado) {
            setCurso(response.data.curso)
        }
      } catch (err) {
        console.log(err);
      }
    }

    const inscribirUsuario = async () =>{
      try {
        const response = await axios.post(`${CONFIG.API_URL}/operarCursos.php`,{'modo':'inscribirPorCodigo', 'id_usuario': datosUser.id, 'codigo':codigo});
       // console.log("dd:"+JSON.stringify(response.data.curso));
        if (response.data.resultado) {
            setCurso(response.data.curso)
        }
      } catch (err) {
        console.log(err);
      }
    }
    const c = curso; // Dado que ahora `curso` es un objeto
    const mostrarNombre = (orden)=>{
      let nombre="";
      switch(orden){
        case "S2":
          nombre='Sala de 2';
        break
        case "S3":
          nombre='Sala de 3';
        break
        case "S4":
          nombre='Sala de 4';
        break
        case "S5":
          nombre='Sala de 5';
        break
        case "In":
          nombre='Espacio Institucional';
        break
        default:
          nombre=orden+'°';
      }
      return nombre
    }
    return (
        <div className="fondo-inscripciones py-5">
            <div className="container-principal">
                <h1 className="titulo-inscripcion text-center my-3">Bienvenido al modulo de Inscripcion</h1>
                <div className="alert alert-info" role="alert">
                    Inscripcion al curso <strong>{mostrarNombre(c.orden)} "{c.denominacion}" ciclo: {c.cohorte}</strong>.
                </div>  
                {!logeado && verFormRegistro && 
                <div className="alert alert-info" role="alert"><p>Ingrese en primer lugar los datos personales del tutor y a continuacion los/as del estudiantes a inscribir en el curso.</p>
                </div>
                 }
                {!verFormRegistro ?
                    <div className="row ">
                        {logeado ?
                          <div className="col-12 my-2 d-flex justify-content-center">
                            <button className="btn-original1"><img src={`../img/logo_CSI.png`} onClick={() =>inscribirUsuario()} className="me-3" width={'40px'} />Inscribirme</button>
                          </div>
                        :
                          <>
                            <div className="col-12 col-sm-6 my-2 ">
                              <h4 className="d-flex justify-content-center">No tengo cuenta en la plataforma</h4>
                                <div className="d-flex justify-content-center">
                                  <button className="btn-original1" onClick={() => setVerFormRegistro(true)}><img src={`../img/logo_CSI.png`} className="me-3" width={'40px'} /> Crear cuenta e inscribirme</button>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 my-2 ">
                              <h4 className="d-flex justify-content-center">¡Ya tengo cuenta en la plataforma!</h4>
                              <div className="d-flex justify-content-center">
                                <button className="btn-original1" onClick={() => {setEsInscripcion(false); navigate("/")}}><img src={`../img/logo_CSI.png`} className="me-3" width={'40px'} /> Ingresar a la cuenta e inscribirme</button>
                              </div>
                            </div>
                          </>
                        }
                    </div>
                    :
                     <InscripcionExternaForm setVerFormRegistro={setVerFormRegistro} codigo={codigo} />   
                }
            </div>
        </div>
    );
}

export default InscripcionExterna;
