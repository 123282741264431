import './css/Laboratorio.css';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cuestionarios from "../actividades/Cuestionarios";
import Actividades from "../actividades/Actividades";

function Laboratorio({acceder, rol}) {
   const [ver, setVer] = useState('');
   const { idMC } = useParams();
   const navigate = useNavigate();
   const idCG =localStorage.getItem('loggeduserCursoGrupo');

    useEffect(() => {
        if(acceder){
            if((rol===null)){
                navigate("/");
            }else{        

            }
        }
    }, []);
    return (    
        <div className="container-principal">
            <h3>Laboratorio</h3>
            <div className='d-flex-1'>
                <button type="button" className="btn-laboratorio m-1" onClick={()=>setVer('actividades')}>Crear actividades</button>
                <button type="button" className="btn-laboratorio m-1" onClick={()=>setVer('cuestionario')}>Crear cuestionarios</button>
                <button type="button" className="btn-laboratorio m-1" onClick={()=>setVer('rubrica')}>Crear rubrica de evaluación</button>
                
                
            </div>
            {ver==='actividades' && <Actividades idMC={idMC} rol={rol} />}
            {ver==='cuestionario' && <Cuestionarios idMC={idMC} rol={rol}  />}
    
            
        </div>
     );
}

export default Laboratorio;