import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

import { fetchConfiguracion } from './servicios/configuracionService.js';

import CajaLogin2 from './componentes/CajaLogin2';
import HeaderRoles from './componentes/HeaderRoles';
import Sidebar from './componentes/Sidebar';
import RolesSelector from './componentes/RolesSelector';
import Footer from './componentes/Footer';
import Perfil from './componentes/usuarios/Perfil';
import CambiarPass from './componentes/usuarios/CambiarPass.js';
import Principal from './componentes/Principal';
import Mensajes from './componentes/mensajes/Mensajes';
import Mensaje from './componentes/mensajes/Mensaje';
import Usuarios from './componentes/usuarios/Usuarios';
import UsuariosLista from './componentes/usuarios/UsuariosLista';
import FichaUsuario from './componentes/usuarios/FichaUsuario.js';
import Formaciones from './componentes/aulas/formaciones';
import Formacion from './componentes/aulas/formacion';
import Cursos from './componentes/aulas/Cursos';
import Curso from './componentes/aulas/Curso';
import MisCursos from './componentes/aulas/MisCursos';
import MiCurso from './componentes/aulas/MiCurso';
import Planificacion from './componentes/aulas/Planificacion';
import Clases from './componentes/aulas/Clases';
import Estudiantes from './componentes/estudiantes/Estudiantes';
import Laboratorio from './componentes/aulas/Laboratorio';
import Agenda from './componentes/mensajes/Agenda.js';
import ConfiguracionAdmin from './componentes/configuracion/ConfiguracionAdmin.js';
import InscripcionExterna from './componentes/aulas/InscripcionExterna.js';
import CursoIA from './componentes/IA/CursoIA.js';
import ResponderCuestionario from './componentes/actividades/ResponderCuestionario.js';
import Calificaciones from './componentes/estudiantes/Calificaciones.js';




function App() {
  const loggedUserJSON = localStorage.getItem('loggedNoteAdapter');
  const loggeduserRolId = localStorage.getItem('loggeduserRolId');

  const [logeado, setLogeado] = useState(false);
  const [rol, setRol] = useState(loggeduserRolId);
  const [esInscripcion, setEsInscripcion] = useState(false);
  const [mensajesSinLeer, setMensajesSinLeer] = useState(0);
  const [configuracion, setConfiguracion] = useState({
    nombre: "",
    sub_titulo: "",
    logo_grande: 'tresatec_logo2.png',
    logo_chico: 'tresatec_logo2.png',
    logo_solo:'tresatec_logo2.png',
    imagen_fondo: "",
    color_principal: '#e6f2fb',
    color_secundario: "",
    color_terciario: "",
    fondo_barra_superior: '#ffffff',
    color_texto_barra_superior: '#000000',
    fondo_barra_lateral: "f2f2f2",
    color_texto_barra_lateral: "",
    formato_icono_perfil: "cuadrado"
  });

  useEffect(() => {
    obtenerConfiguracion();
    if (loggedUserJSON) {
      setLogeado(loggedUserJSON);
      if (loggeduserRolId != null) {
        setRol(loggeduserRolId);
      } else {
        setRol(null);
      }
    }
    
  }, [])

  //const socket = io('http://localhost:4000')

  //useEffect(() => {
    //const socket = io('http://localhost:4000');

    //socket.on('mensaje_nuevo', (mensaje) => {
     // console.log('Nuevo mensaje recibido:', mensaje);
     // setMensajesSinLeer((prevCount) => prevCount + 1);
  // });

  //  return () => {
      //socket.disconnect();
 //   };
 // }, []);

  async function obtenerConfiguracion() {
    try {
      const data = await fetchConfiguracion();
      setConfiguracion(data);
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
    }
  }
 
  const acceder = (estado) => {
    setLogeado(estado);
  }

  const rolSelect = (idRol) => {
    setRol(idRol);
  }

  return (
    <BrowserRouter>
      {logeado ? (
        <>
          <HeaderRoles acceder={acceder} rolSelect={rolSelect} configuracion={configuracion} mensajesSinLeer={mensajesSinLeer} setMensajesSinLeer={setMensajesSinLeer} />
          {rol != null ? <Sidebar acceder={acceder} rolSelect={rolSelect} rol={rol} configuracion={configuracion} /> : ''}
        </>
      ) : (
        !esInscripcion ? <CajaLogin2 acceder={acceder} /> : ''
      )}
      <Routes>
        <Route path="/Inscripcion/:codigo" element={<InscripcionExterna logeado={logeado} esInscripcion={esInscripcion} setEsInscripcion={setEsInscripcion} />} />
        <Route path="/" element={logeado &&<RolesSelector acceder={acceder} rol={rol} rolSelect={rolSelect} />} />
        {logeado && (
          <>
            <Route path="/Principal" element={<Principal acceder={acceder} rol={rol} mensajesSinLeer={mensajesSinLeer} configuracion={configuracion} />} />
            <Route path="/Dashboard" element={<ConfiguracionAdmin acceder={acceder} rol={rol} />} />
            <Route path="/Mensajes" element={<Mensajes acceder={acceder} rol={rol} />} />
            <Route path="/Chat" element={<Mensajes acceder={acceder} rol={rol} />} />
            <Route path="/Mensajes/:origen/:mensajeId" element={<Mensaje acceder={acceder} rol={rol} />} />
            <Route path="/Agenda" element={<Agenda acceder={acceder} rol={rol} />} />
            <Route path="/Usuarios" element={<Usuarios acceder={acceder} rol={rol} />} />
            <Route path="/Usuarios/:userId" element={<UsuariosLista acceder={acceder} rol={rol} rolSelect={rolSelect} />} />
            <Route path="/Ficha/:usuarioId" element={<FichaUsuario configuracion={configuracion} />} />
            <Route path="/Formaciones" element={<Formaciones acceder={acceder} rol={rol} rolSelect={rolSelect} />} />
            <Route path="/Formaciones/:formacionId" element={<Formacion acceder={acceder} rol={rol} />} />
            <Route path="/Cursos" element={<Cursos acceder={acceder} rol={rol} rolSelect={rolSelect} />} />
            <Route path="/Cursos/:cursoId" element={<Curso acceder={acceder} rol={rol} />} />
            <Route path="/MC" element={<MisCursos logeado acceder={acceder} rol={rol} />} />
            <Route path="/MC/:idMC" element={<MiCurso acceder={acceder} rolSelect={rolSelect} rol={rol} />} />
            <Route path="/MC/:idMC/p/:idCG" element={<Planificacion acceder={acceder} rol={rol} />} />
            <Route path="/MC/:idMC/c" element={<Clases acceder={acceder} rol={rol} />} />
            <Route path="/MC/:idMC/c/:idCl" element={<Clases acceder={acceder} rol={rol} />} />
            <Route path="/MC/:idMC/e" element={<Estudiantes acceder={acceder} rol={rol} />} />
            <Route path="/MC/:idMC/l" element={<Laboratorio acceder={acceder} rol={rol}/>} />
            <Route path="/cuestionario/:formId" element={<ResponderCuestionario />} />
            <Route path="/MC/:idMC/IA/:idCG" element={<CursoIA />} />
            <Route path="/Perfil" element={<Perfil />} />
            <Route path="/Pass" element={<CambiarPass acceder={acceder} />} />
            <Route path="/Calificaciones" element={<Calificaciones acceder={acceder} />} />
            
           
            
          </>
        )}
      </Routes>
      {/* Footer siempre visible */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
