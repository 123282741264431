import './css/Aulas.css';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { show_alerta } from '../../funciones.js';

import ListaClases from './ListaClases';
import Clase from './Clase';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarClases.php`;
const loggeduserCurso = localStorage.getItem('loggeduserCurso');
const loggeduserCursoGrupo = localStorage.getItem('loggeduserCursoGrupo');

const datoClase = {
    id: null,
    id_curso: loggeduserCurso,
    id_curso_grupo: loggeduserCursoGrupo,
    titulo_corto: '',
    tema: '',
    imagen_arriba: '',
    presentacion: '',
    desarrollo: '',
    cierre: '',
    imagen_abajo: '',
    fecha: '',
    creado_por: ''
}

function Clases({ acceder, rol }) {
    const [clase, setClase] = useState(datoClase);
    const [clases, setClases] = useState([]);
    const [nuevaClase, setNuevaClase] = useState(false);
    const [editarClase, setEditarClase] = useState(false);
    const [editaMaterial, setEditaMaterial] = useState(false);
    const [editaActividad, setEditaActividad] = useState(false);
    const [verAreaForos, setVerAreaForos] = useState(false);
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const { idMC, idCl } = useParams();

    const data = {
        'id_curso': clase.id_curso,
        'id_curso_grupo': loggeduserCursoGrupo,
        'modo': 'buscarClases'
    };

    useEffect(() => {
        
        if (acceder) {
            if ((rol === null)) {
                navigate("/");
            } else {        
                if (localStorage.getItem('loggeduserClasesCurso')) {
                    const parse = JSON.parse(localStorage.getItem('loggeduserClasesCurso'));
                    parse.sort((a, b) => b.id - a.id);
                    localStorage.setItem('loggeduserClasesCurso', JSON.stringify(parse));
                    setClases(parse);
                    setClase(parse[0]);
                    
                    //let posicion = 0;
                    //const idClase = Number(idCl); // Convertir idCl a número
                    //if (!isNaN(idClase)) {
                    //    const index = parse.findIndex(c => c.id === idClase);
                    //    console.log('index:'+index);
                    //    if (index !== -1) {
                    //        posicion = index;
                    //    }
                    //}
                    //setClases(parse);
                    //setClase(parse[posicion]);
                    //console.log('clase:'+JSON.stringify(parse[posicion]));
                } else {
                    setClases([]);
                }
                consultarAPI(data);
            }
        } else {
            localStorage.clear();
            navigate('/');
        }                   
    }, [nuevaClase]);

    const consultarAPI = (data) => {
        setVisible(true);
        axios.post(URL, data)
        .then(res => {
            if (!res.data.error) { 
                const parse = res.data.sort((a, b) => a.id - b.id);
                
                let posicion = 0;
                const idClase = Number(idCl); // Convertir idCl a número
                if (!isNaN(idClase)) {
                    const index = parse.findIndex(c => c.id === idClase);
                    console.log('index:'+index);
                    if (index !== -1) {
                        posicion = index;
                    }else{
                        posicion =0
                    }
                }
                setClases(parse);
                setClase(parse[posicion]);
            //    setClase(parse[0]);
                localStorage.setItem('loggeduserClasesCurso', JSON.stringify(parse));
            } else {
                setClases([]);
            }
            setVisible(false);
        })
        .catch(err => {
            console.log(err);
        });
    };

    const handleNUevo =()=>{
        if(!nuevaClase){ 
            setClase(datoClase);
            //console.log("clase: "+clase);
        }else{
            //si no hay clases
            //if(){
                //setClase(datoClase)
            //}else{
                //si hay clases tomo la primera
                //setClase(clases[0]);
            //}
        }
        setNuevaClase(!nuevaClase);
    }

    const eliminarClase = (id) =>{
        const MySwal= withReactContent(Swal); 
        MySwal.fire({
            title: '¿Seguro de eliminar la clase?',
            icon: 'question', 
            text: 'No se podrá dar marcha atrás',
            showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
        })
        .then(res=>{
            if(res.isConfirmed){
                enviarSolicitud('DELETE',{'id':id})
                setClase(datoClase);
                //consultarAPI(data);
                navigate(`/MC/${idMC}`);
            }
            else{
                show_alerta(' NO fue eliminado');
            }
        });
    };

    const enviarSolicitud = async (metodo, parametros) =>{
        await axios({method:metodo, url:URL, data:parametros})
        .then(res =>{
		    console.log(res.data);
            var tipo = res.data[0];
            var msj = res.data[1];
            //console.log(msj+'-'+tipo);
            show_alerta(msj,tipo);            
            setEditarClase(false);
            setNuevaClase(false);
            consultarAPI(data);
        })
        .catch(err=>{
            show_alerta('Error en la solicitud ','error');
            console.log(err);
        })
    }


    const cambiarClase = (direccion) => {
        const indexActual = clases.findIndex(c => c.id === clase.id);
        let nuevoIndex = indexActual + direccion;

        if (nuevoIndex >= 0 && nuevoIndex < clases.length) {
            setClase(clases[nuevoIndex]);
        }
    };

    return (    
        <div className="container-principal">
            <h3>Clases</h3>
            <div className="row">
                <div className="col-12 col-sm-3">
                    {(rol === 6 || rol === 5 || rol === 9 || rol === 11) &&
                    <button 
                    type='button' 
                    className={`btn btn-sm ${!nuevaClase ? 'btn-primary' : 'btn-secondary'} ${(editarClase || editaMaterial || verAreaForos) ? 'disabled' : ''}`} 
                    onClick={ () => handleNUevo() }
                    >
                        {!nuevaClase ? '+ Clase' : "Cancelar Nuevo"}
                    </button>
                    }
                    <ListaClases
                        clases={clases}
                        clase={clase}
                        setClase={setClase} 
                        visible={visible}
                        editarClase={editarClase}
                        nuevaClase={nuevaClase}
                        editaMaterial={editaMaterial}
                        editaActividad={editaActividad} />
                </div>
                
                <div className="col-12 col-sm-9">
                    {(nuevaClase || clase.id==null)?'':
                        ((rol==6 || rol==5 || rol ==9 || rol==11) && !editaMaterial && !editaActividad) &&
                        <div>
                            { !verAreaForos &&
                            <>
                                <button 
                                type='button' 
                                className={`btn btn-sm ${!editarClase ? 'btn-warning':'btn-secondary'} ${(verAreaForos) ? 'disabled':''} mx-1`} 
                                onClick={()=>setEditarClase(!editarClase)}
                                >
                                    <i className="fa-regular fa-pen-to-square"></i> {!editarClase ? 'Editar clase' : "Cancelar Editar"}
                                </button>
                                <button 
                                type='button' 
                                className={`btn btn-sm btn-danger ${(editarClase || verAreaForos) ? 'disabled':''} mx-1`} 
                                onClick={()=>eliminarClase(clase.id)} 
                                >
                                    <i className="fa-regular fa-trash-can"></i> Elimina Clase
                                </button>
                            </>
                            }
                            <button 
                            type='button' 
                            className={`btn btn-sm btn-success ${editarClase ? 'disabled':''} mx-1`} 
                            onClick={()=>setVerAreaForos(!verAreaForos)}
                            >
                                <i className="fa-regular fa-message me-1"></i> {!verAreaForos ?'Ver área Foros' : 'Cerrar área Foros'}
                            </button>
                        </div>
                    }

                    <Clase 
                        rol={rol}
                        clases={clases}
                        clase={clase} 
                        enviarSolicitud={enviarSolicitud}
                        editarClase={editarClase}
                        nuevaClase={nuevaClase}  
                        setEditaMaterial={setEditaMaterial} 
                        editaMaterial={editaMaterial}
                        editaActividad={editaActividad}
                        setEditaActividad={setEditaActividad} 
                        verAreaForos={verAreaForos}
                            />

                   
                      
                    

                    {/* Barra de Navegación */}
                    {clases.length > 1 && (
                        <div className="navigation-bar mt-3">
                            <button className="btn btn-secondary me-2" 
                                    onClick={() => cambiarClase(-1)} 
                                    disabled={clases.findIndex(c => c.id === clase.id) === 0}>
                                Anterior
                            </button>
                            <button className="btn btn-secondary" 
                                    onClick={() => cambiarClase(1)} 
                                    disabled={clases.findIndex(c => c.id === clase.id) === clases.length - 1}>
                                Siguiente
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Clases;
