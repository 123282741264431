import './css/Aulas.css';
import { useState } from "react";

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import toolbar from "./toolbar";


function ClaseForm({clase, enviarSolicitud }) {
    const [form, setForm] = useState(clase);
    const module ={
        toolbar:toolbar,
    }
    const handleChange =(e) => {
        setForm({
            ...form,
                [e.target.name]: e.target.value    
        })
    }
    const handleChangePresentacion = (value) => {
        setForm({...form,'presentacion': value})
      } 
      const handleChangeDesarrollo = (value) => {
        setForm({...form,'desarrollo': value})
      } 
      const handleChangeCierre = (value) => {
        setForm({...form,'cierre': value})
      } 

    const handleSubmint =(e) => {
        e.preventDefault();
        console.log("form.id:"+form.id);
        //console.log("desarrollo:"+form.desarrollo);
        const formData = new FormData();
        formData.append('id', form.id);
        formData.append('id_curso', form.id_curso);
        formData.append('id_curso_grupo', form.id_curso_grupo);
        formData.append('titulo_corto', form.titulo_corto);
        formData.append('tema', form.tema);
        formData.append('presentacion', form.presentacion);
        formData.append('desarrollo', form.desarrollo);
        formData.append('cierre', form.cierre);
        if(form.id===null){
            formData.append('nuevo', 'SI');
        }else{
            formData.append('nuevo', 'NO');
        }
        enviarSolicitud("POST",formData);
    }

    return (    
        <>
        <form id='form1' className='form-clase ' onSubmit={handleSubmint} >
            <input type="hidden" id="id" name="id" defaultValue={form.id} /> 
            <input type="hidden" id="id_curso" name="id_curso" defaultValue={form.id_curso} /> 

            <div className="mb-3 row">
                <label htmlFor="titulo_corto" className="col-sm-1 col-form-label">Titulo</label>
                <div className="col-sm-11">
                    <input type="text" className="form-control" id="titulo_corto" name='titulo_corto' defaultValue={form.titulo_corto} onChange={handleChange} />
                </div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="tema" className="col-sm-1 col-form-label">Tema</label>
                <div className="col-sm-11">
                    <input type="text" className="form-control" id="tema" name='tema' defaultValue={form.tema} onChange={handleChange}/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="presentacion" className="form-label">Presentación de la clase</label>
                <ReactQuill  
                    modules={module} 
                    theme="snow" 
                    placeholder={"escribir aqui..."}
                    value={form.presentacion} 
                    onChange={handleChangePresentacion} />
                
            </div>
            <div className="mb-3">
                <label htmlFor="presentacion" className="form-label">Desarrollo de la clase</label>
                <ReactQuill  
                    modules={module} 
                    theme="snow" 
                    placeholder={"escribir aqui..."}
                    value={form.desarrollo} 
                    onChange={handleChangeDesarrollo} />
                
            </div>
            <div className="mb-3">
                <label htmlFor="presentacion" className="form-label">Cierre de la clase</label>
                <ReactQuill  
                    modules={module} 
                    theme="snow" 
                    placeholder={"escribir aqui..."}
                    value={form.cierre} 
                    onChange={handleChangeCierre} />
                
            </div>
            <button type='submit' className='btn btn-sm btn-success m-1' >Guardar</button>
        </form>
        </>
     );
}

export default ClaseForm;