import './css/Aulas.css';
import ClaseMaterialesForm from "./ClaseMaterialesForm";
import Espera from '../Espera';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarClases.php`;

function ClaseMateriales({setCantMateriales, enviarSolicitud, id_clase, editaMaterial, setEditaMaterial}) {
    const [materialClase, setMaterialClase] = useState([]);
    const [visible, setVisible] = useState(false);
   
    const iconos={
        'pdf':'fa-file-pdf', 
        'docx': 'fa-file-word','doc': 'fa-file-word',
        'xlsx': 'fa-file-excel', 
        'jpg': 'fa-file-imagen', 'jpeg': 'fa-file-imagen',}
    const data= {
        'id_clase' : id_clase,
        'modo': 'buscarMaterialClase'
    }
    
    useEffect(() => {
        buscaMaterialClase(data);
        console.log(' materialClase: '+ materialClase)
    }, [setCantMateriales, enviarSolicitud, id_clase, editaMaterial, setEditaMaterial]);

    const buscaMaterialClase =  (d) =>{
        setVisible(true);
        axios.post(URL, d)
        .then(res =>{
            if(!res.data.error){   
                setMaterialClase(res.data);
                setCantMateriales(res.data.length);
                //console.log('cantMaterial: '+res.data.length)
            }else{
               setMaterialClase([]);
               setCantMateriales(0);
            }
            setVisible(false);           
        })
        .catch(err=>{
            console.log(err);
        })   
    }

    const quitarMaterial=(id_material_clase)=>{
        enviarSolicitud('DELETE',{'id':id_material_clase,'tabla':'material_clase'})
        setEditaMaterial(false);
    }

    return (    
        <div>
            {editaMaterial && <ClaseMaterialesForm  id_clase={id_clase} setEditaMaterial={setEditaMaterial} enviarSolicitud={enviarSolicitud} />}
            {!visible ? 
                materialClase.length!=0 && materialClase.map((mcl)=>(
                    <div key={mcl.id} className="material-clase-link row">
                        <div className='col-11'>
                        {mcl.tipo=='vinculo'?<i className="fa-solid fa-link"></i> :<i className={`fa-regular ${iconos[mcl.extension]}`}></i>}
                         <a href={`${CONFIG.API_URL}/materialcursos/${mcl.link}`} target='_blank' className='ms-2 texto-item'>{mcl.nombre.substr(0, 35)}-{mcl.id}- {mcl.link}</a>
                        </div>
                         {editaMaterial&&<div className='col-1 icon-eliminar'><a onClick={()=>quitarMaterial(mcl.id)}><i className="fa-regular fa-trash-can"></i></a></div>}
                    </div>
                ))
            : <div className='container m-3'><Espera visible={visible} /></div>
            }                
        </div>
     );
}


export default ClaseMateriales;