import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Principal.css';
import CONFIG from '../../config.js';
import { Bar } from 'react-chartjs-2'; // instalar npm install chart.js react-chartjs-2
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { show_alerta } from '../../funciones.js';

// Registrar las escalas y elementos de Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const URL_INFO = `${CONFIG.API_URL}/info_principal.php`;
const URL_CALENDARIO = `${CONFIG.API_URL}/operarCalendario.php`;

function PrincipalAdmin({ mensajesSinLeer, rol }) {
    const [usuarios, setUsuarios] = useState(0);
    const [cursos, setCursos] = useState(0);
    const [estudiantes, setEstudiantes] = useState(0);
    const [docentes, setDocentes] = useState(0);
    const [matriculaData, setMatriculaData] = useState([]);
    const userId =localStorage.getItem('loggeduserId');
    const [eventos, setEventos] = useState([]);

    useEffect(() => {
        buscaInfo();
        obtenerEventos();
    }, []);

    const buscaInfo = () => {
        axios.get(URL_INFO)
            .then(res => {
                if (res.data.usuarios) { 
                    setUsuarios(res.data.usuarios);
                    setCursos(res.data.cursos);
                    setEstudiantes(res.data.estudiantes);
                    setDocentes(res.data.docentes);
                    setMatriculaData(res.data.matricula); // Asumiendo que la API retorna estos datos
                    console.log(res.data.matricula)
                } else {
                    setUsuarios(0);
                    setCursos(0);
                    setEstudiantes(0);
                    setDocentes(0);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    // Preparamos los datos para el gráfico
    const years = [...new Set(matriculaData.map(item => item.año))];
    const datasets = years.map(year => {
        return {
            label: `Año ${year}`,
            data: matriculaData.filter(item => item.año === year).map(item => item.total_estudiantes),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        }
    });

    const labels = matriculaData.map(item => `grado ${item.orden}°`);

    const data = {
        labels: labels,
        datasets: datasets
    };

    const obtenerEventos = async () => {
        try {
            const res = await axios.get(URL_CALENDARIO);
            if (Array.isArray(res.data)) {
                // Aplicar filtros según el rol
                const eventosFiltrados = res.data.filter(evento => {
                    const esPropio = evento.creada_por == userId;
    
                    let filtroPorRol = false;
                    switch (rol) {
                        case 1:
                        case 2:
                            filtroPorRol = ['todos', 'todosA', 'todosD', 'todosE','todosT','todosM'].includes(evento.tipo_recordatorio);
                            break;
                        case 3:
                        case 4:
                            filtroPorRol = ['todos', 'todosA'].includes(evento.tipo_recordatorio);
                            break;
                        case 5:
                        case 6:
                        case 9:
                            filtroPorRol = ['todos', 'todosD'].includes(evento.tipo_recordatorio);
                            break;
                        case 7:
                            filtroPorRol = ['todos', 'todosE'].includes(evento.tipo_recordatorio);
                            break;
                        case 8:
                            filtroPorRol = ['todos', 'todosT'].includes(evento.tipo_recordatorio);
                            break;
                        case 12:
                            filtroPorRol = ['todos', 'todosM'].includes(evento.tipo_recordatorio);
                            break;
                        default:
                            filtroPorRol = false;
                            break;
                    }
    
                    return esPropio || filtroPorRol;
                });
    
                setEventos(eventosFiltrados);
            } else {
                setEventos([]);
                console.error('La respuesta de la API no es un array:', res.data);
            }
        } catch (err) {
            console.error('Error al obtener eventos:', err);
        }
    };

    const eventosHoy = () => {
        const fechaActual = new Date();
        fechaActual.setHours(0, 0, 0, 0); // Asegúrate de comparar solo las fechas

        return eventos.filter((evento) => {
            const fechaEvento = new Date(evento.fecha);
            fechaEvento.setHours(0, 0, 0, 0);
            return fechaEvento.getTime() === fechaActual.getTime();
        });
    };

    const proximosEventos = (dias_proximos) => {
        const fechaActual = new Date();
        const fechaLimite = new Date();
        fechaLimite.setDate(fechaActual.getDate() + dias_proximos);

        return eventos.filter((evento) => {
            const fechaEvento = new Date(evento.fecha);
            return fechaEvento >= fechaActual && fechaEvento <= fechaLimite;
        });
    };

    return (    
        <div>
            <h5>Área {rol==2? 'administrador':'equipo directivo'}</h5>
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <div className='card px-2'>
                        <div className="principal-card">
                            <div className='principal-caja-circulo-logo'><i className='fa-solid fa-envelope'></i></div>
                            <div className='principal-caja-borde-logo'></div>
                            <div className='principal-card-titulo '>Mensajes sin leer</div>
                            <div className='d-flex justify-content-center principal-texto-info-rojo'>{mensajesSinLeer}</div>                                    
                        </div>
                        <div className=''>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h5>Eventos de Hoy:</h5>
                                    </div>
                                    {eventosHoy().map((evento) => (
                                        <div key={evento.id_evento}>
                                            <strong>{evento.evento}</strong> - {evento.hora_desde} a {evento.hora_hasta}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h5>Próximos eventos <small>(15 días)</small>:</h5 >
                                    </div>
                                    {proximosEventos(15).map((evento) => (
                                        <div key={evento.id_evento}>
                                            <strong>{evento.evento}</strong> - {new Date(evento.fecha).toLocaleDateString()} ({evento.hora_desde} - {evento.hora_hasta})
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                                    
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-6'>
                    <div className='card px-4 pb-4'>
                        <h2>Informe gráfico</h2>
                        <h5>Estudiantes por año y grado</h5>
                        <div className='principal-grafico'>
                            <Bar data={data} />
                        </div>
                       <div>
                            {matriculaData.map(item => 
                                `grado ${item.orden}°`
                            
                            )}
                       </div>
                    </div>
                   
                </div>
                <div className='col-12 col-md-12 col-lg-2 card'>
                <div className='row'>
                    <div className='col-6 col-md-3 col-lg-12 d-flex justify-content-center'>
                        <div className='cuadro-info-simple cuadro-fondo1'>
                            <i className='fa-solid fa-users'></i>
                            <div className='texto-info1'>{usuarios} Usuarios</div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3 col-lg-12 d-flex justify-content-center'>
                        <div className='cuadro-info-simple cuadro-fondo2'>
                            <i className="fa-solid fa-boxes-stacked"></i>
                            <div className='texto-info1'>{cursos} cursos</div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3 col-lg-12 d-flex justify-content-center'>
                        <div className='cuadro-info-simple cuadro-fondo1'>
                            <i className="fa-solid fa-graduation-cap"></i>
                            <div className='texto-info1'>{estudiantes} estudiantes</div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3 col-lg-12 d-flex justify-content-center'>
                        <div className='cuadro-info-simple cuadro-fondo2'>
                            <i className="fa-solid fa-chalkboard-user"></i>
                            <div className='texto-info1'>{docentes} Docentes</div>
                        </div>                        
                    </div>
                </div>
 
                </div>
            </div>
        </div>
    );
}

export default PrincipalAdmin;
