import './css/CajaLogin2.css';
import {useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CONFIG from '../config';

const URL_LOGIN  = `${CONFIG.API_URL}/login.php`;

function CajaLogin2({acceder}){
    const [error, setError] = useState(null);
   // const [espera, setEspera] = useState(false);
    const refUsuario = useRef(null);
    const refClave = useRef(null);

    const [biometricSupported, setBiometricSupported] = useState(false);

    useEffect(() => {
        //if (window.PublicKeyCredential) {
        //  navigator.credentials
        //    .get({ publicKey: { challenge: new Uint8Array([/* challenge */]) } })
        //    .then(() => setBiometricSupported(true))
        //    .catch((err) => {
        //      if (err.name === "NotAllowedError") {
        //        setBiometricSupported(true); // El usuario tiene soporte biométrico
        //      } else {
        //        console.error("Autenticación biométrica no soportada:", err);
        //      }
        //    });
        //}
      }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        
        //datos
        const data= {
            'usuario' :refUsuario.current.value,
            'clave' : refClave.current.value
        }
        axios.post(URL_LOGIN, data)
        .then(res =>{ //capturamos el resultado del backend
           // console.log('resultado: '+res.data);
            if(res.data.conectado){ 
                acceder(true); //conectado propiedad que viene del backend
                localStorage.setItem('loggedNoteAdapter', true);
                localStorage.setItem('loggeddatosuser', JSON.stringify(res.data.infoUser));
                localStorage.setItem('loggeduser', res.data.infoUser.usuario);
                localStorage.setItem('loggeduserId', res.data.infoUser.id );
                localStorage.setItem('loggeduserNombre', res.data.infoUser.nombre +' '+ res.data.infoUser.apellido);
                //console.log("dato:"+res.data);  
            }else{
                setError(res.data.error); //error propiedad que viene del backend
                acceder(false); //conectado propiedad que viene del backend
            }
        })
        .catch(err=>{
            console.log(err);
        })
    }    

    const handleBiometricLogin = async () => {
        try {
          const publicKeyCredential = await navigator.credentials.get({
            publicKey: {
              challenge: new Uint8Array([/* challenge desde el servidor */]),
              allowCredentials: [{
                id: new Uint8Array([/* ID de la credencial */]),
                type: 'public-key',
                transports: ['internal'] // huellas digitales integradas
              }]
            }
          });
          
          // Procesar la autenticación, enviar datos al servidor, etc.
          if (publicKeyCredential) {
            acceder(true);
            localStorage.setItem('loggedNoteAdapter', true);
            // Almacenar otros datos necesarios
          }
        } catch (err) {
          console.error("Autenticación biométrica fallida:", err);
        }
      };
      

    return(
    <main className='caja-login-fondo py-2'>
        <div className='d-flex justify-content-center'>
                <div className='contenedor-caja-login '>
                    <div className='cuerpo-login'>
                    <div className='cuerpo-login-circulo p-3'><img src='../img/logo_CSI.png' className='img-fluid'/></div>
                        <div className='cuerpo-login-trapecio'></div>
                        <div className='cuerpo-login-cuadrado'>
                            <form onSubmit={handleLogin} className='login-wrapper my-auto w-100 text-center'>
                                <h1 className="login-title">Ingreso</h1>
                                { error && 
                                    <div className='alerta text-danger'>
                                        {error}
                                    </div>
                                }
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <div className='fuente'>Usuario</div>
                                    <div className="input-group input-icon-group">
                                        <span className=" icon-wrapper">
                                            <i className="fa-solid fa-user icon-style"></i>
                                        </span>
                                        <Form.Control
                                        className='input'
                                        type="text" 
                                        placeholder="Usuario" 
                                        required
                                        ref={refUsuario} 
                                    />
                                    </div>
                                </Form.Group>

                                
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <div className="fuente">Contraseña</div>
                                    <div className="input-group input-icon-group">
                                        <span className=" icon-wrapper">
                                            <i className="fa-solid fa-lock icon-style"></i>
                                        </span>
                                        <Form.Control
                                            className="input"
                                            type="password"
                                            placeholder="Ingrese Contraseña"
                                            required
                                            ref={refClave}
                                        />
                                    </div>
                                </Form.Group>

                                { biometricSupported && 
                                    <Button
                                        className="btn "
                                        variant=""
                                        onClick={handleBiometricLogin}
                                    >
                                        <i className="fa-solid fa-fingerprint"></i>
                                    </Button>
                                }

                                <button
                                    className="login-btn"
                                    type='submit'
                                >ingresar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    </main>
    );

}

export default CajaLogin2;

