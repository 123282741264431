import './css/Aulas.css';
import React, { useState, useEffect } from 'react';
import {QRCodeSVG} from 'qrcode.react'; // Importa la biblioteca QR Code

import { useParams, useNavigate } from "react-router-dom";
import CursoGrupoEstudiantes from './CursoGrupoEstudiantes';
import CursoEquipoDocente from './CursoEquipoDocente';
import useCursoData from '../../hooks/useCursoData';
import CONFIG from '../../config';

const URL_PLATAFORMA =`${CONFIG.BASE_URL}`;

function Curso({ acceder, rol }) {
    const { cursoId } = useParams();
    const navigate = useNavigate();
    const [verInfo, setVerInfo] = useState('Docentes');
    const { curso, docentesCurso, estudiantesCurso, inscripciones } = useCursoData(cursoId);
    const [cantidadEstudiantes, setCantidadEstudiantes] = useState(0);

    const handleBack = () => {
        const searchState = JSON.parse(localStorage.getItem('searchState'));
        if (searchState) {
            localStorage.removeItem('searchState');
           // console.log(searchState);
            navigate('/Cursos', { state: searchState });
        } else {
            navigate('/');
        }
    };
    let cantidadE=estudiantesCurso.length;
    useEffect(() => {
        if (!acceder) {
            localStorage.clear();
            navigate('/');
            return null;
        }
    
        if (rol === null || rol > 3) {
            navigate("/");
            return null;
        }
       
        setCantidadEstudiantes(cantidadE);
    
    }, [acceder, rol, cantidadE]);

    
    if (!curso) {
        return <div>Cargando...</div>; // Manejo de estado de carga
    }

    const mostrarNombre = (orden)=>{
        let nombre="";
        switch(orden){
          case "S2":
            nombre='Sala de 2';
            break        
          case "S3":
            nombre='Sala de 3';
          break
          case "S4":
            nombre='Sala de 4';
          break
          case "S5":
            nombre='Sala de 5';
          break
          case "In":
            nombre='Espacio Institucional';
          break
          default:
            nombre=orden+'°';
        }
        return nombre
      };

    const c = curso; // Dado que ahora `curso` es un objeto
   // console.log(c.id)
    return (
        <div className='container-principal'>
            <div className='row'>
                <div className='col-12 col-sm-1'>
                    <button type='button' className='btn btn-outline-secondary btn-sm mb-3' onClick={handleBack}>
                        <i className="fa-solid fa-angles-left"></i> Volver
                    </button>    
                </div>
                <div className='col-12 col-sm-11'>
                    <h4>{mostrarNombre(c.orden)} - {c.nombre} cohorte {c.cohorte} </h4>
                </div>
                <div className='row'>
                    <div className='col-6 text-end'>
                        <h5>grupo {c.denominacion}</h5>
                    </div>
                    <div className='col-6 text-'>
                        <span className={`text-${c.estado === 'Abierto' ? 'success' : 'warning'}`}>
                            <i className={`small1 fa-solid fa-lock${c.estado === 'Abierto' ? '-open' : ''} mr-3`}></i> curso {c.estado}
                        </span>
                    </div>
                </div>
                <div className={`alert alert-${inscripciones ? 'info' : 'danger'} me-2`} role='alert'>
                    <div className='row'>
                        <div className='col'>
                                <span>Periodo de inscripción desde <b>{c.fecha_inicio}</b> hasta <b>{c.fecha_fin}</b></span>
                            <h6 className={`text-${inscripciones ? 'success' : 'danger'}`}>
                                Inscripciones al curso {inscripciones ? <>abiertas hasta {c.fecha_fin}</> : 'cerradas'}
                            </h6>
                        </div>
                        <div className='col-4'>
                            
                            {c.codigo_inscripcion && 
                            <div>
                                <h6 className='d-flex justify-content-center'>Codigo de inscripción</h6> 
                                <div className='d-flex justify-content-center' ><QRCodeSVG value={`${URL_PLATAFORMA}Inscripcion/${c.codigo_inscripcion}`} size={128} /></div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs my-2">
                    <li className="nav-item">
                        <a className={`nav-link ${verInfo === 'Docentes' ? 'active' : ''}`} aria-current="page" href="#" onClick={() => setVerInfo('Docentes')}>Docentes <span className="badge text-bg-light">{docentesCurso.length}</span></a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${verInfo === 'Estudiantes' ? 'active' : ''}`} aria-current="page" href="#" onClick={() => setVerInfo('Estudiantes')}>Estudiantes <span className="badge text-bg-light">{cantidadEstudiantes}</span></a>
                    </li>
                </ul>

                {verInfo === 'Docentes' &&
                    <div className='row'>
                        <CursoEquipoDocente id_curso_grupo={c.id_curso_grupo} docentesCurso={docentesCurso} />
                    </div>
                }
                {verInfo === 'Estudiantes' &&
                    <div className='row'>
                       {c.dictado==='Estudiantes' && <CursoGrupoEstudiantes id_curso_grupo={c.id_curso_grupo} cantidadEstudiantes={cantidadEstudiantes} setCantidadEstudiantes={setCantidadEstudiantes} />}
                    </div>
                }
            </div>
        </div>
    );
}

export default Curso;
