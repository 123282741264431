import './css/estudiantes.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Espera from '../Espera';
import PerfilLogo from '../usuarios/PerfilLogo.js';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { show_alerta } from '../../funciones.js';

import CONFIG from '../../config';
import MostrarEncabezadoInstancias from './MostrarEncabezadoInstancias.js';

const URL = `${CONFIG.API_URL}/operarEstudiantes.php`;
const URL_ACTUALIZAR = `${CONFIG.API_URL}/operarValoraciones.php`;

function Estudiantes({ acceder, rol }) {
    const [estudiantes, setEstudiantes] = useState([]);
    const [valoraciones, setValoraciones] = useState([]);
    const [colInstancias, setColInstancias] = useState([]);
    const [formValues, setFormValues] = useState({}); // Para almacenar los valores seleccionados de los select

    const [visible, setVisible] = useState(false);
    const [vista, setVista] = useState('calificaciones'); // Estado para controlar la vista activa
    
    const navigate = useNavigate();
    const loggeduserCurso = localStorage.getItem('loggeduserCurso');
    const loggeduserCursoGrupo = localStorage.getItem('loggeduserCursoGrupo');

    useEffect(() => {
        if (acceder) {
            if (rol === null) {
                navigate("/");
            } else {  
                const data = {
                    'id_curso': loggeduserCurso,
                    'id_grupo': loggeduserCursoGrupo,
                    'modo': 'buscarEstudiantesCurso'
                };
                obtenerEstudiantesyValoraciones(data);
            }
        } else {
            localStorage.clear();
            navigate('/');
        }                   
    }, [acceder, rol, navigate, loggeduserCurso, loggeduserCursoGrupo]);

    const obtenerEstudiantesyValoraciones = (dato) => {
        setVisible(true);
        axios.post(URL, dato)
        .then(res => {
            if (!res.data.error) {
                //console.log("estudiantes:"+res.data.valoraciones); 
                setEstudiantes(res.data.estudiantes);
                setValoraciones(res.data.valoraciones);
            } else {
                setEstudiantes([]);
                setValoraciones([]);
            }
            setVisible(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    // Función para manejar el cambio de vista
    const handleVistaChange = (nuevaVista) => {
        setVista(nuevaVista);
    }


    const eliminarEstudiante = (id) =>{
        const MySwal= withReactContent(Swal); 
        MySwal.fire({
            title: '¿Seguro de Quitar al/la estudiante de la clase?',
            icon: 'question', 
            text: 'si desea volvera ingresarlo lo debera hacer el administrador',
            showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
        })
        .then(res=>{
            if(res.isConfirmed){
                enviarSolicitud('DELETE',{'id':id,'id_grupo': loggeduserCursoGrupo,'modo': 'eliminarEstudiante'})
            }
            else{
                show_alerta(' NO fue eliminado');
            }
        });
    };

    const enviarSolicitud = async (metodo, parametros) =>{
        setVisible(true);
        await axios({method:metodo, url:URL, data:parametros})
            .then(res => {
                if (!res.data.error) {
                    // Filtra al estudiante eliminado de la lista
                    setEstudiantes(estudiantes.filter(e => e.id !== parametros.id));
                    console.log(res.data);
                    var tipo = res.data[0];
                    var msj = res.data[1];
                    show_alerta(msj,tipo);    
                } else {
                    alert('Error al eliminar al estudiante');
                }
                setVisible(false);
            })
            .catch(err => {
                show_alerta('Error en la solicitud ','error');
                console.log(err);
                setVisible(false);
            });
    };
    
    // Manejar cambios en los select
    const handleSelectChange = (idUsuario, idInstancia, valor) => {
        console.log('usuario:'+idUsuario+'instancia:'+idInstancia+'valor:'+valor);
        setFormValues((prev) => ({
            ...prev,
            [`${idUsuario}_${idInstancia}`]: { id_usuario: idUsuario, id_instancia: idInstancia, valor: valor }
        }));
    };

    // Enviar valores al backend
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = Object.values(formValues);

        if (data.length === 0) {
            Swal.fire('Error', 'No hay cambios que guardar', 'error');
            return;
        }
        
        const response = await axios.post(URL_ACTUALIZAR, { 'modo': 'guardarValoraciones', 'valoraciones': data });
        console.log(response.data);
        if (!response.data.error) {
            Swal.fire('Éxito', 'Calificaciones guardadas', 'success');
            setValoraciones((prev) =>
                prev.map((v) =>
                    data.find((d) => d.id_usuario === v.id_usuario && d.id_instancia === v.id_instancia)
                        ? { ...v, ...data.find((d) => d.id_usuario === v.id_usuario && d.id_instancia === v.id_instancia) }
                        : v
                )
            );
        } else {
            Swal.fire('Error', response.data.message, 'error');
        }
    };

    return (
        <div className="container-principal">
            <small>curso: #{loggeduserCurso}</small><small>curso_grupo: #{loggeduserCursoGrupo}</small>
            <h3 className='estudiantes-titulo'>Estudiantes en el curso {estudiantes.length} {rol == 1 && 
                <button type='button' className='btn btn-outline-info btn-sm'>
                    + <i className="fa-solid fa-graduation-cap"></i>
                </button>
            }</h3>
            
            {(rol == 6 || rol == 5) &&
                <div className='row'>
                    <div className="col-12">
                        <ul className="nav nav-tabs justify-content-end">
                            <li className="nav-item">
                                <button 
                                    className={`nav-link ${vista === 'calificaciones' ? 'active' : ''}`} 
                                    onClick={() => handleVistaChange('calificaciones')}
                                >
                                    Calificaciones
                                </button>
                            </li>
                            <li className="nav-item">
                                <button 
                                    className={`nav-link ${vista === 'asistencia' ? 'active' : ''}`} 
                                    onClick={() => handleVistaChange('asistencia')}
                                >
                                    Asistencia
                                </button>
                            </li>
                            <li className="nav-item">
                                <button 
                                    className={`nav-link ${vista === 'actividades' ? 'active' : ''}`} 
                                    onClick={() => handleVistaChange('actividades')}
                                >
                                    Actividades
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            }

            {!visible ? 
                <>
                {estudiantes.length > 0 ? (
                    <form onSubmit={handleSubmit}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Estudiante</th>
                                    {vista === 'calificaciones' && (
                                        <MostrarEncabezadoInstancias
                                            curso={loggeduserCurso}
                                            setColInstancias={setColInstancias}
                                        />
                                    )}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map((est) => (
                                    <tr key={est.id}>
                                        <td className="col-sm-5 col-12">
                                            <PerfilLogo usuario={est} version="extendida" />
                                        </td>
                                        {vista === 'calificaciones' &&
                                            colInstancias.map((i, index) => {
                                                const calificacion = valoraciones.find(
                                                    (v) => v.id_instancia === i.id && v.id_usuario === est.id
                                                );

                                                const fechaActual = new Date();
                                                const fechaInicio = new Date(i.fecha_inicio);
                                                const fechaCierre = new Date(i.fecha_cierre);

                                                const habilitarInput = fechaActual >= fechaInicio && fechaActual <= fechaCierre;

                                                // Seleccionar el arreglo de opciones basado en el tipo de calificación
                                                let opciones = [];
                                                if (i.tipo_calificacion === 'Valorativa') {
                                                    opciones = ['','SC', 'EP', 'S', 'MS', 'Sup'];
                                                } else if (i.tipo_calificacion === 'Numerica') {
                                                    opciones = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
                                                } else if (i.tipo_calificacion === 'Logro') {
                                                    opciones = ['pendiente', 'Logrado'];
                                                } else if (i.tipo_calificacion === 'Participacion') {
                                                    opciones = ['', 'Participo', 'no participo'];
                                                }

                                                return (
                                                    <td key={index} className="col text-center">
                                                        <select
                                                            className={`estudiante-select-${i.tipo_calificacion.toLowerCase()} ${
                                                                calificacion ? 'estudiante-calificado' : ''
                                                            }`}
                                                            disabled={!habilitarInput}
                                                            value={
                                                                 calificacion?.valor
                                                            }
                                                            onChange={(e) =>
                                                                handleSelectChange(est.id, i.id, e.target.value)
                                                            }
                                                        >
                                                            {opciones.map((opcion, idx) => (
                                                                <option key={idx} value={opcion}>
                                                                    {opcion}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                );
                                            })}
                                        <td className="col-sm-3 col-12 estudiante-botonera">
                                            {(rol == 6 || rol == 5) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-warning btn-sm"
                                                    onClick={() => eliminarEstudiante(est.id)}
                                                >
                                                    - <i className="fa-solid fa-graduation-cap"></i>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">
                                Guardar Calificaciones
                            </button>
                        </div>
                    </form>
                ) : (
                   <div>Sin estudiantes</div>
                )}                    
                </> 
                : <Espera />
            }
        </div>
    );
}

export default Estudiantes;
