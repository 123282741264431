import { useState, useEffect, useRef } from "react";
import "./css/Sidebar.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function Sidebar({ acceder, rol, rolSelect, configuracion }) {
    const [expandida, setExpandida] = useState(false);
    const sidebarRef = useRef(null);

    const navigate = useNavigate();
    let location = useLocation();

    const loggeduserCurso = localStorage.getItem('loggeduserCurso');


    useEffect(() => {
        const handleClickOutside = (event) => {
          if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setExpandida(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    
    useEffect(() => {
        if (!acceder) {
            localStorage.clear();
            rolSelect(null);
            navigate('/');
        }
    }, [acceder, navigate, rolSelect]);

    const volver = () => {
        localStorage.removeItem('loggeduserCurso');
        localStorage.removeItem('loggeduserClasesCurso');
        localStorage.removeItem('loggeduserCursoGrupoO');
        setExpandida(false);
    }

    return (
        <div className={`${expandida ? 'show-sidebar' : ''}`} ref={sidebarRef}>
            <aside className="sidebar" style={{
            backgroundColor: configuracion.fondo_barra_lateral,
            color: configuracion.color_texto_barra_lateral
            }}>
                <div className="toggle">
                    <span className="burger" onClick={() => setExpandida(!expandida)}><span></span><span></span><span></span></span>
                </div>
                <div className="side-inner">
                    <span className="logo"><img src={`../img/${configuracion.logo_grande}`} alt="" className="img-fluid" /></span>
                    <div className="nav-menu">
                        {location.pathname !== '/' && (
                            <ul>
                                {(loggeduserCurso === null) && (rol !== 1) && (
                                    <li>
                                        <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/Principal'} >
                                            <i className="wrap-icon fa-solid fa-home mr-3"></i>
                                            <span className="menu-text">Principal</span>
                                        </Link>
                                    </li>
                                )}
                                {rol === 1 && (
                                    <li>
                                        <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/Dashboard'} >
                                            <i className="wrap-icon fa-solid fa-sliders mr-3"></i>
                                            <span className="menu-text">Dashboard</span>
                                        </Link>
                                    </li>
                                )}
                                {rol < 4 && (
                                    <>
                                        <li>
                                            <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/Usuarios'}>
                                                <i className="wrap-icon fa-brands fa-slideshare mr-3"></i>
                                                <span className="menu-text">Usuarios</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/Formaciones'}>
                                                <i className="wrap-icon fa-solid fa-box-archive mr-3"></i>
                                                <span className="menu-text">Estructura de cursos</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/Cursos'}>
                                                <i className="wrap-icon fa-solid fa-boxes-stacked mr-3"></i>
                                                <span className="menu-text">Espacios/Cursos</span>
                                            </Link>
                                        </li>
                                        
                                    </>
                                )}
                                {(rol== 7 ||rol == 6 || rol==5) && loggeduserCurso === null && (
                                    <li>
                                        <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/MC'}>
                                            <i className="wrap-icon fa-solid fa-box-archive mr-3"></i>
                                            <span className="menu-text">Mis Cursos</span>
                                        </Link>
                                    </li>
                                )}
                                {(rol== 7 ||rol == 6 || rol == 5) && loggeduserCurso !== null && (
                                    <>
                                        <li>
                                            <Link className="d-flex align-items-center mb-4" to={'/MC'} onClick={volver}>
                                                <i className="wrap-icon fa-regular fa-circle-left mr-3"></i>
                                                <span className="menu-text">Volver</span>
                                            </Link>
                                        </li>
                                        
                                        <li>
                                            <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={`/MC/${loggeduserCurso}`}>
                                                <i className="wrap-icon fa-regular fa-folder-open mr-3"></i>
                                                <span className="menu-text">Curso</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={`/MC/${loggeduserCurso}/e`}>
                                                <i className="wrap-icon fa-solid fa-graduation-cap mr-3"></i>
                                                <span className="menu-text">Estudiantes</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={`/MC/${loggeduserCurso}/c`}>
                                                <i className="wrap-icon fa-solid fa-file-signature mr-3"></i>
                                                <span className="menu-text">Clases</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={`/MC/${loggeduserCurso}/l`}>
                                                <i className="wrap-icon fa-solid fa-flask mr-3"></i>
                                                <span className="menu-text">Laboratorio de Práctica</span>
                                            </Link>
                                        </li>
                                    </>
                                )}

                                {rol == 13 && (  
                                    <>
                                    <li>
                                        <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/usuarios/0'}>
                                            <i className="fa-solid fa-users m-1"></i>
                                            <span className="menu-text">Usuarios activos</span>
                                        </Link>
                                        
                                    </li>
                                    <li>
                                    <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/usuarios/6'}>
                                         <i className="fa-solid fa-chalkboard-user m-1 ms-2 "></i>
                                        <span className="menu-text">Docentes</span>
                                    </Link>
                                    
                                </li>
                                <li>
                                <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/usuarios/7'}>
                                     <i className="fa-solid fa-graduation-cap m-1 ms-2"></i>
                                    <span className="menu-text">Estudiantes</span>
                                </Link>
                                
                            </li>
                            </>
                                )}
                                    <li>
                                        <Link className="d-flex align-items-center" onClick={()=>setExpandida(false)} to={'/Agenda'}>
                                            <i className="wrap-icon fa-solid fa-calendar-days mr-3"></i>
                                            <span className="menu-text">Agenda</span>
                                        </Link>
                                    </li>
                            </ul>
                        )}
                    </div>
                </div>
            </aside>
        </div>
    );
}

export default Sidebar;
