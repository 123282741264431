import React from 'react';
import './Footer.css'; // Opcional para estilos específicos

const Footer = () => {
  return (
    <footer className='footer' style={{ }}>
      <p className='footer-text'>power by <a href='http://www.tresatec.com.ar/' target='_blank'><img className='footer-logo' src='https://www.institutopetitdemeurville.com.ar/img/tresatec-logo-mix.png'/></a> © 2024  </p>
    </footer>
  );
};

export default Footer;
